import React from "react";
import logo from "../assets/images/logo.png";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import bg2 from "assets/images/bg2.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    mimHeight: "100vh",
    paddingTop: 50,
    paddingBottom: 250,
    backgroundImage: `url(${bg2})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  heading: {
    margin: 30,
    // marginBottom: 100,
    fontSize: 50,
    color: theme.palette.secondary.main,
  },
  map: {
    width: 900,
    height: 450,
    // borderRadius: 24,
    [theme.breakpoints.down("sm")]: {
      // padding: 30,
      width: "100vw",
      height: 400,
    },
  },
  contactContent: {
    marginTop: 30,
    marginBottom: 50,
    // paddingRight: 200,
    [theme.breakpoints.down("sm")]: {
      // padding: 20,
      spacing: "auto",
      margin: 0,
    },
    [theme.breakpoints.up("sm")]: {
      spacing: 9,
    },
  },
}));

export const Contact = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className={classes.root} id="contact">
      <Grid item xs={12} sm={12} md={12} xl={12}>
        <img alt="Asas Logo" src={logo} style={{ width: 150, marginTop: 30 }} />
      </Grid>
      <h1 className={classes.heading}>Lets Get In Touch</h1>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <iframe
            title="Asas Location"
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3625.2427468354826!2d46.68302151500741!3d24.684180758379224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f034387b52263%3A0x9c49b64e3c72ec97!2sAl%20Olaya%2C%20Riyadh%2011564!5e0!3m2!1sen!2ssa!4v1611880676651!5m2!1sen!2ssa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3626.2711839693557!2d46.773049314998715!3d24.648792284154172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjTCsDM4JzU1LjciTiA0NsKwNDYnMzAuOSJF!5e0!3m2!1sen!2ssa!4v1615767492413!5m2!1sen!2ssa"
            className={classes.map}
            style={{ border: 0 }}
            aria-hidden="false"
          />
        </Grid>
        <Grid item>
          <Grid
            container
            spacing={isSmall ? 2 : 9}
            direction="row"
            justify="center"
            alignItems="flex-start"
            className={classes.contactContent}
          >
            <Grid item xs={10} sm={3} md={3} xl={3}>
              <Typography
                align="left"
                color="primary"
                variant="h6"
                gutterBottom
              >
                Head Office
              </Typography>
              <Typography align="left" variant="subtitle1" color="secondary">
                Salahuddin Ayoubi st. Malaz.
              </Typography>
              <Typography align="left" variant="subtitle1" color="secondary">
                Riyadh,Saudi Arabia
              </Typography>
            </Grid>
            <Grid item xs={10} sm={3} md={3} xl={3}>
              <Typography
                align="left"
                color="primary"
                variant="h6"
                gutterBottom
              >
                Contact
              </Typography>
              <Typography align="left" variant="subtitle1" color="secondary">
                +966 557292552
              </Typography>
              <Typography align="left" variant="subtitle1" color="secondary">
                info@asassystems.com
              </Typography>
            </Grid>

            <Grid item xs={10} sm={3} md={3} xl={3}>
              <Typography
                align="left"
                color="primary"
                variant="h6"
                gutterBottom
              >
                Operation
              </Typography>
              <Typography align="left" variant="subtitle1" color="secondary">
                operations@asassystems.com
              </Typography>
            </Grid>
            <Grid item xs={10} sm={3} md={3} xl={3}>
              <Typography
                align="left"
                color="primary"
                variant="h6"
                gutterBottom
              >
                Sales
              </Typography>
              <Typography align="left" variant="subtitle1" color="secondary">
                siddiqui.fawaz@asassystems.com
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
