import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import a from "../assets/clients/1.png";
import b from "../assets/clients/2.png";
import c from "../assets/clients/3.jpg";
import d from "../assets/clients/4.png";
import e from "../assets/clients/5.png";
import f from "../assets/clients/6.png";
import g from "../assets/clients/7.png";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 50,
    paddingTop: 50,
  },
  content: {
    // display: "block",
    margin: "auto",
    maxWidth: 900,
  },
  mainHeading: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 50,
    margin: 50,
  },
  img: {
    maxWidth: 200,
    maxHeight: 100,
    padding: 10,
  },
}));

export const OurClients = () => {
  const classes = useStyles();

  return (
    <div className={classes.root} id="products">
      <h1 className={classes.mainHeading}>Our Clients</h1>

      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.content}
      >
        <Grid item>
          <img alt="Client Logo" src={a} className={classes.img} />
        </Grid>
        <Grid item>
          <img alt="Client Logo" src={b} className={classes.img} />
        </Grid>
        <Grid item>
          <img alt="Client Logo" src={c} className={classes.img} />
        </Grid>
        <Grid item>
          <img alt="Client Logo" src={d} className={classes.img} />
        </Grid>
        <Grid item>
          <img alt="Client Logo" src={e} className={classes.img} />
        </Grid>
        <Grid item>
          <img alt="Client Logo" src={f} className={classes.img} />
        </Grid>
        <Grid item>
          <img alt="Client Logo" src={g} className={classes.img} />
        </Grid>
      </Grid>
    </div>
  );
};
