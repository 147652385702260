import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import arrow from "assets/images/arrow.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 600,
    // margin: "20px 0px",
    // backgroundImage: `url(${arrow})`,
    backgroundImage: `url(${arrow})`,
    // backgroundImage: `url(https://image.freepik.com/free-vector/economy-stock-market-growth-finacial-recovery_1017-27105.jpg)`,
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    maxWidth: 600,

    color: "white",
  },
}));

export const OurMission = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h1 className={classes.text} style={{ fontSize: 50, fontWeight: "bold" }}>
        Mission
      </h1>
      <h2 className={classes.text} style={{ textTransform: "capitalize" }}>
        We strive to exceed the expectations of our varied target segments by
        sourcing, offering and serving specific needs and wants, while working
        with the globally reputed brands and products in the business sector we
        represent, thereby leading to complete customer satisfaction.
      </h2>
    </div>
  );
};
