import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import fourSsn from "assets/projects/4ssn.jpg";
import garnada from "assets/projects/garnada.jpg";
import hilton from "assets/projects/hilton.jpg";
import kafd from "assets/projects/kafd.jpg";
import kap1 from "assets/projects/kap1.jpg";
import ksu from "assets/projects/ksu.jpeg";
import lifestyle from "assets/projects/lifestyle.jpg";
import marriott from "assets/projects/marriott.jpg";
import metro from "assets/projects/metro.jpg";
import salam from "assets/projects/salam.jpeg";
import sama from "assets/projects/sama.jpg";
import sharma from "assets/projects/sharma.jpg";
import strip from "assets/projects/strip.jpg";
import term5 from "assets/projects/term5.png";
import { Footer } from "Components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 200,
  },
  productContainer: {
    padding: 20,
    height: 280,
    minWidth: 230,
    borderRadius: 24,
    padding: 20,
    backgroundColor: "#fff",
    borderBottomWidth: 12,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: "#87c846",
    borderStyle: "solid",
    boxShadow: "0 8px 30px rgb(0 0 0 / 25%)",
    "&:hover": {
      boxShadow: "0 8px 30px rgb(0 0 0 / 10%) ,0 20px 30px rgb(0 0 0 / 20%)",
      // transform: "scale(1.01) translate(0px, 20px)",
      // border: "#87c846 1px solid",
    },
    transition: theme.transitions.create(["all"], {
      duration: 700,
      easing: theme.transitions.easing.easeInOut,
    }),
    [theme.breakpoints.up("xl")]: {
      height: 420,
    },
  },
  mainHeading: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 50,
    margin: 50,
  },
  heading: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 30,
  },
  subHeading: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    margin: "10px 0px",
    textTransform: "lowercase",
  },
  btn: {
    background: "linear-gradient(25deg, #009B7F 5%, #87c846 90%)",
    border: 0,
    borderRadius: 27,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 54,
    width: 300,
    margin: 40,
    padding: "0 30px",
  },
  img: {
    width: "100%",
    height: 220,
    objectFit: "cover",
    borderRadius: 12,
    [theme.breakpoints.up("xl")]: {
      height: 350,
    },
  },
}));

const Projects = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const products = [
    {
      company: "Riyadh Metro",
      image: metro,
    },
    {
      company: "Gardana mall extension, Riyadh",
      image: garnada,
    },
    {
      company: "KAFD, Riyadh",
      image: kafd,
    },
    {
      company: "Sharma Neom",
      image: sharma,
    },
    {
      company: "SAMA headquarter maintenance, Riyadh",
      image: sama,
    },
    {
      company: "Hilton Garden INN, Tabuk",
      image: hilton,
    },
    {
      company: "Marriott Hotel DQ, Riyadh",
      image: marriott,
    },
    {
      company: "Strip Mall, Riyadh",
      image: strip,
    },
    {
      company: "Al Salam Palace, Jeddah ",
      image: salam,
    },
    {
      company: "King Saud University (Dental Department)",
      image: ksu,
    },
    {
      company: "King Abdullah Project (KAP-1, KAP-2)",
      image: kap1,
    },
    {
      company: "Airport Terminal 5, Riyadh",
      image: term5,
    },
    {
      company: "Four Season Hotel, Kingdom Tower, Riyadh",
      image: fourSsn,
    },
    {
      company: "Life Style Mall, Olayya, Riyadh",
      image: lifestyle,
    },
  ];

  return (
    <div className={classes.root}>
      <h1 className={classes.mainHeading}>Projects</h1>

      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        {products.map((i, index) => {
          return (
            <Grid
              key={index}
              item
              xs={10}
              sm={5}
              md={3}
              xl={3}
              style={{ margin: 10 }}
            >
              <div className={classes.productContainer}>
                <img
                  alt={"Project Image"}
                  src={i.image}
                  className={classes.img}
                />
                <h4 className={classes.heading}>{i.company}</h4>
              </div>
            </Grid>
          );
        })}
      </Grid>
      <div style={{ marginTop: 200 }}>
        <Footer />
      </div>
    </div>
  );
};

export default Projects;
