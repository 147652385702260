import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { Footer } from "Components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 200,
  },
  productContainer: {
    padding: 20,
    height: 280,
    minWidth: 230,
    borderRadius: 24,
    padding: 20,
    backgroundColor: "#fff",
    borderBottomWidth: 12,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: "#87c846",
    borderStyle: "solid",
    boxShadow: "0 8px 30px rgb(0 0 0 / 25%)",
    "&:hover": {
      boxShadow: "0 8px 30px rgb(0 0 0 / 10%) ,0 20px 30px rgb(0 0 0 / 20%)",
      // transform: "scale(1.01) translate(0px, 20px)",
      // border: "#87c846 1px solid",
    },
    transition: theme.transitions.create(["all"], {
      duration: 700,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  mainHeading: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 50,
    margin: 50,
  },
  heading: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 30,
  },
  subHeading: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    margin: "10px 0px",
    textTransform: "lowercase",
  },
  btn: {
    background: "linear-gradient(25deg, #009B7F 5%, #87c846 90%)",
    border: 0,
    borderRadius: 27,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 54,
    width: 300,
    margin: 40,
    padding: "0 30px",
  },
  img: {
    width: "100%",
    height: 220,
    objectFit: "cover",
    borderRadius: 12,
  },
}));

const Message = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <h1 className={classes.mainHeading}>From CEO's Desk...</h1>
      <img
        alt={"Asas Ceo"}
        src={require("assets/asas_ceo2.jpg").default}
        style={{
          // boxShadow: "0 10px 20px 0px #43546294",
          height: 240,
          borderRadius: 8,
          display: "block",
          marginBottom: 30,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />

      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xl={6} md={6} xs={9} sm={10}>
          <Typography
            color="secondary"
            variant="h3"
            align="center"
            gutterBottom
          >
            Glance About AMC Group
          </Typography>

          <Typography color="secondary" variant="body1" gutterBottom paragraph>
            AMC Group (Al Muhaideb Contracting), is a family company founded by
            <b> Mr. Abdul Aziz Abdullah Al Muhaideb </b>in 2002. Over a period
            of time, due to our trusted customers, workmanship values and ethics
            we achieved many milestones. Our reputation is our most treasured
            asset and the foundation on which we have built our company.
          </Typography>
          <Typography color="secondary" variant="body1" gutterBottom paragraph>
            In an effort to expand its portfolio, AMC has branched out its
            business into the HVAC industry, Printing & Packaging Industry, Food
            Industry and has recently entered the Educational sector to provide
            an international education system which will help mould our society
            to international standards.
          </Typography>
          <br />
          <br />
          <Typography color="secondary" variant="h6" gutterBottom>
            Under the umbrella of AMC Group, we cater…
          </Typography>
          <ul>
            <li>
              <Typography color="secondary" variant="body1" gutterBottom>
                <b>ASAS System Est. (ASAS)</b> is one of KSA’s leading
                stockist/distributor of HVAC Industry products. We aim to meet
                the major requirements for HVAC industry by providing the most
                comprehensive range of innovative and reliable products to meet
                our customer requirements.
              </Typography>
            </li>
            <li>
              <Typography color="secondary" variant="body1" gutterBottom>
                <b>JOUD FOOD, </b>is one of KSA’s leading stockist/distributor
                of reputed brands of food products, a retail chain catering to
                entire KSA food markets.
              </Typography>
            </li>
            <li>
              <Typography color="secondary" variant="body1" gutterBottom>
                <b>AL MUHAIDEB PACKAGING FACTORY,</b> is one of KSA’s leading
                manufacturer/supplier for packaging products for the entire
                field.
              </Typography>
            </li>
            <li>
              <Typography color="secondary" variant="body1" gutterBottom>
                <b>AL MUHAIDEB BOOK STORE,</b> is one of KSA’s leading book
                store, which cater books for all multi language syllabus for
                colleges/universities.
              </Typography>
            </li>
            <li>
              <Typography color="secondary" variant="body1" gutterBottom>
                <b>SUCCESS PRINTING,</b> is one of the KSA’s leading print
                centre, catering to the majority of the printing needs of the
                industry.
              </Typography>
            </li>
            <li>
              <Typography color="secondary" variant="body1" gutterBottom>
                <b>SUCCESS INTERNATIONAL SCHOOL,</b> is one of KSA’s leading
                International School providing education at par with the
                international standards, with an excellent campus in the heart
                of Riyadh.
              </Typography>
            </li>
          </ul>

          <br />
          <br />
          <Typography color="secondary" variant="body1" gutterBottom paragraph>
            <b>AMC</b> offers customized solutions to the Saudi market, using
            the latest technology and integrated platforms of diversify its
            services to its customers and the confidence that AMC will
            completely meet their business aspirations makes AMC the better, if
            not, the best choice.. These achievements and successes are a clear
            reflection of AMC group’s commitment to the highest quality service
            and attention to detail to compete at local and international
            levels.
          </Typography>
          <Typography color="secondary" variant="body1" gutterBottom paragraph>
            Each of us at the AMC Group, work with utmost sincerity and honesty
            to offer the best quality services to our valuable customers to meet
            their expectations and comprehend their issues while offering them
            the best possible solutions. This is in line with our commitment to
            contribute to the achievement of sustainable development goals by
            strengthening our engagement with all our customers.
          </Typography>
          <Typography color="secondary" variant="body1" gutterBottom paragraph>
            We look forward to your continued support and patronage.
          </Typography>
          <br />
          <br />
          <Typography
            color="secondary"
            variant="body1"
            align="right"
            gutterBottom
          >
            {/* <img
              alt={"Asas Ceo"}
              src={require("assets/asas_ceo2.jpg").default}
              style={{
                height: 240,
                borderRadius: 8,
                display: "block",
                marginLeft: "auto",
                marginRight: 20,
              }}
            /> */}
            <b>Abdul Aziz Abdullah Al Muhaideb</b>
            <br />
            <b>CEO – AMC Group of companies</b>
          </Typography>
        </Grid>
      </Grid>
      <div style={{ marginTop: 200 }}>
        <Footer />
      </div>
    </div>
  );
};

export default Message;
