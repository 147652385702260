import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import armacell from "../assets/images/armacell.png";
import kimmco from "../assets/images/kimmco.png";
import tembo from "../assets/images/tembo.png";
import prime from "../assets/images/prime.png";
import focus from "../assets/images/focus.png";
import abro from "../assets/images/abro.png";
import foster from "../assets/images/foster.png";
import xchem from "../assets/images/xchem.png";
import star from "../assets/images/star.jpg";
import icool from "../assets/images/icool.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 500,
    paddingBottom: 50,
    paddingTop: 30,
  },
  content: {
    maxWidth: 700,
  },
  productContainer: {
    padding: 10,
    height: 340,
    minWidth: 230,
    borderRadius: 24,
    padding: 30,
    backgroundColor: "#fff",
    boxShadow: "0 8px 30px rgb(0 0 0 / 10%) ,0 20px 30px rgb(0 0 0 / 20%)",
    borderBottomWidth: 12,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: "#87c846",
    borderStyle: "solid",

    "&:hover": {
      boxShadow: "0 8px 30px rgb(0 0 0 / 25%)",
      transform: "scale(1.01) translate(0px, 20px)",
      border: "#87c846 1px solid",
    },
    transition: theme.transitions.create(["all"], {
      duration: 500,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  mainHeading: {
    color: theme.palette.secondary.main,
    fontSize: 50,
    margin: 50,
  },
  heading: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 30,
  },
  subHeading: {
    color: theme.palette.secondary.main,
    fontSize: 16,
    margin: "10px 0px",
  },
  btn: {
    background: "linear-gradient(25deg, #009B7F 5%, #87c846 90%)",
    border: 0,
    borderRadius: 27,
    boxShadow: "0 3px 5px 2px rgba(135, 200, 70, .3)",
    color: "white",
    height: 54,
    width: 300,
    margin: 40,
    marginBottom: 50,
    padding: "0 30px",
    "&:hover": {
      boxShadow: "0 5px 10px 2px rgba(135, 200, 70, .6)",
      transform: "scale(1.01)",
    },
    transition: theme.transitions.create(["all"], {
      duration: 700,
      easing: theme.transitions.easing.easeInOut,
    }),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  img: {
    display: "block",
    margin: "auto",
    maxWidth: 150,
    minHeight: 50,
    maxHeight: 80,
    objectFit: "contain",
  },
}));

export const OurProducts = () => {
  const classes = useStyles();
  const history = useHistory();
  const products = [
    {
      company: "ARMACEL",
      products: [
        {
          title: "AC Armaflex",
        },
        {
          title: "AP Armaflex",
        },
        {
          title: "Armachek Silver",
        },
        {
          title: "ArmaComfort AB AL PUR",
        },
        {
          title: "Armaflex Adhesive 520 with Acc",
        },
        {
          title: "Armalight Alu",
        },
      ],
      logo: armacell,
    },
    {
      company: "TEMBO STAR PIPE SUPPORT SYSTEM",
      products: [
        {
          title: "Pipe Hanger With EPDM Lining",
        },
        {
          title: "Pipe Hanger Without Lining",
        },
        {
          title: "Sprinkler Hanger",
        },
        {
          title: "Clevis hanger",
        },
        {
          title: "Rubber Support Inserts",
        },
        {
          title: "Vibration Ssolator",
        },
        {
          title: "U Strap",
        },
      ],
      logo: tembo,
    },
    {
      company: "KIMMCO ISOVER",
      products: [
        {
          title: "Internal Insulation For Ducts",
        },
        {
          title: "External Insulation For Ducts",
        },
        {
          title: "Pipe Sections For HVAC Applications",
        },
        {
          title: "Acoustic Insulation",
        },
      ],
      logo: kimmco,
    },
    {
      company: "FOCUS AIRCONDITIONS",
      products: [
        { title: "Flexible Duct (Insulated/Un-Insualted)" },
        {
          title: "Flexible Duct Connector",
        },
        {
          title: "Duct Flange System",
        },
        { title: "Duct Pin" },
      ],
      logo: focus,
    },
    {
      company: "PRIME AIRCONDITION LLC",
      products: [
        {
          title: "Filter Hepa",
        },
        {
          title: "Fire Dumper Smoke Damper",
        },
        {
          title: "Non Return Damper-Pressure Relief Damper",
        },
        {
          title: "Sound attenuator",
        },
        {
          title: "Variable air volume",
        },
        {
          title: "Volume control damper",
        },
      ],
      logo: prime,
    },
  ];

  return (
    <div className={classes.root} id="products">
      <h1 className={classes.mainHeading}>Our Products</h1>

      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        {products.map((i, index) => {
          if (index > 4) return;
          return (
            <Grid
              item
              xs={10}
              sm={5}
              md={3}
              xl={3}
              style={{ margin: 10 }}
              key={index}
            >
              <div className={classes.productContainer}>
                {i.logo ? (
                  <img
                    alt={i.company + " Logo"}
                    src={i.logo}
                    className={classes.img}
                  />
                ) : (
                  <div className={classes.img} />
                )}
                <h5 className={classes.heading}>{i.company}</h5>
                {i.products.map((k, idx) => {
                  return (
                    <p
                      key={idx}
                      className={classes.subHeading}
                      style={{ textAlign: "left" }}
                    >
                      {"  -  "} {k.title}
                    </p>
                  );
                })}
              </div>
            </Grid>
          );
        })}
      </Grid>
      <Button
        className={classes.btn}
        onClick={() => {
          history.push("/Products");
        }}
      >
        View All
      </Button>
      <a
        href="https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/ASAS_profile.pdf?alt=media&token=26a4997f-84d0-4bca-8f80-0fc0999909b7"
        target="_blank"
        rel="noopener"
        rel="nofollow"
      >
        <Button className={classes.btn}>Download</Button>
      </a>
    </div>
  );
};
