import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

export const Header = ({ dark }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "absolute",
        top: 0,
        zIndex: 5,
        paddingLeft: "10%",
        paddingRight: "10%",
        width: "80%",
        paddingTop: 20,
        // backgroundColor: "white",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: 10,
          paddingLeft: 14,
          marginTop: -20,
          paddingTop: 20,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
      >
        <a href="/#home">
          <img alt="Asas Logo" src={logo} style={{ width: 80 }} />
        </a>
      </div>
      <Hidden smDown>
        <div
          style={{
            display: "flex",
            width: "60%",
            justifyContent: "space-between",
          }}
        >
          <a href="/#home">
            <h3 style={{ color: dark ? "black" : "white" }}>Home</h3>
          </a>
          <div>
            {/* <Button aria-controls="simple-menu" aria-haspopup="true">
            About Us
          </Button> */}
            <h3
              onClick={handleClick}
              style={{ color: dark ? "black" : "white", cursor: "pointer" }}
            >
              About Us
            </h3>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <a href="/#aboutus">
                <MenuItem onClick={handleClose} style={{ color: "black" }}>
                  About Us
                </MenuItem>
              </a>
              <a href="/Message" style={{ color: "black" }}>
                <MenuItem onClick={handleClose}>CEO's Message</MenuItem>
              </a>
            </Menu>
          </div>
          <a href="/#contact">
            <h3 style={{ color: dark ? "black" : "white" }}>Contact</h3>
          </a>
          <a href="/Products">
            <h3 style={{ color: dark ? "black" : "white" }}>Products</h3>
          </a>
          <a href="/Projects">
            <h3 style={{ color: dark ? "black" : "white" }}>Projects</h3>
          </a>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MenuRoundedIcon
              fontSize="large"
              onClick={handleClick}
              style={{ color: dark ? "black" : "white" }}
            />
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <a href="/#home" style={{ color: "black" }}>
              <MenuItem onClick={handleClose}>Home</MenuItem>
            </a>
            <a href="/#aboutus">
              <MenuItem onClick={handleClose} style={{ color: "black" }}>
                About Us
              </MenuItem>
            </a>
            <a href="/Message" style={{ color: "black" }}>
              <MenuItem onClick={handleClose}>CEO's Message</MenuItem>
            </a>
            <a href="/#contact" style={{ color: "black" }}>
              <MenuItem onClick={handleClose}>Contact</MenuItem>
            </a>
            <a href="/Products" style={{ color: "black" }}>
              <MenuItem onClick={handleClose}>Products</MenuItem>
            </a>
            <a href="/Projects" style={{ color: "black" }}>
              <MenuItem onClick={handleClose}>Projects</MenuItem>
            </a>
          </Menu>
        </div>
      </Hidden>
    </div>
  );
};
