import React from "react";
import { Link } from "react-router-dom";

export const Footer = ({ dark }) => {
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: 0,
          zIndex: 5,
          width: "100%",
          paddingTop: 20,
          // backgroundColor: "white",
        }}
      >
        <p style={{ textAlign: "center" }}>
          Copyright © 2021 ASAS System Est. Developed & Managed By -{" "}
          <a
            href="https://thrworks.dev"
            target="_blank"
            rel="noopener"
            style={{
              fontWeight: "bold",
              color: "#111f03",
              textShadow: "0px 1px 1px rgb(0 0 0 / 50%)",
            }}
          >
            {`ThrWorks`}
          </a>
        </p>
      </div>
    </div>
  );
};
