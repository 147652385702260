import media1 from "assets/Hero/Asas1.jpg";
import media2 from "assets/Hero/Asas2.jpg";
import media3 from "assets/Hero/Asas3.jpg";
import media4 from "assets/Hero/Asas4.jpg";

const m1 =
  "https://blog.architizer.com/wp-content/uploads/1635_20_1-800x528-1.png";
const m2 = "https://wallpapercave.com/wp/wp4628172.jpg";
export const media = [media1, media4, media2];
export const mediaByIndex = (index) => media[index % media.length];
