import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import armacell from "../assets/images/armacell.png";
import kimmco from "../assets/images/kimmco.png";
import tembo from "../assets/images/tembo.png";
import prime from "../assets/images/prime.png";
import focus from "../assets/images/focus.png";
import abro from "../assets/images/abro.png";
import foster from "../assets/images/foster.png";
import xchem from "../assets/images/xchem.png";
import star from "../assets/images/star.jpg";
import icool from "../assets/images/icool.jpg";
import aerozeal from "../assets/images/aerozeal.png";
import { Footer } from "Components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 200,
  },
  productContainer: {
    height: 340,
    minWidth: 230,
    borderRadius: 24,
    padding: 30,
    backgroundColor: "#fff",
    boxShadow: "0 8px 30px rgb(0 0 0 / 10%) ,0 20px 30px rgb(0 0 0 / 20%)",
    borderBottomWidth: 12,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: "#87c846",
    borderStyle: "solid",
    "&:hover": {
      boxShadow: "0 8px 30px rgb(0 0 0 / 25%)",
      transform: "scale(1.01) translate(0px, 20px)",
      border: "#87c846 1px solid",
    },
    transition: theme.transitions.create(["all"], {
      duration: 700,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  mainHeading: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 50,
    margin: 50,
  },
  heading: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 30,
  },
  subHeading: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    margin: "10px 0px",
    // textTransform: "lowercase",
  },

  btn: {
    background: "linear-gradient(25deg, #009B7F 5%, #87c846 90%)",
    border: 0,
    borderRadius: 27,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 54,
    width: 300,
    margin: 40,
    padding: "0 30px",
  },
  img: {
    display: "block",
    margin: "auto",
    maxWidth: 150,
    minHeight: 50,
    maxHeight: 80,
    objectFit: "contain",
  },
}));

const AllProducts = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const products = [
    {
      company: "ARMACEL",
      products: [
        {
          title: "AC Armaflex",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FArmacell%2FAC%20ARMAFLEX.pdf?alt=media&token=54bb2226-4c2d-4006-845c-a1915a2774d2",
        },
        {
          title: "AP Armaflex",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FArmacell%2FAP%20ARMAFLEX.pdf?alt=media&token=3a26fece-c911-43c6-bce0-0e9ec57aba7f",
        },
        {
          title: "Armachek Silver",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FArmacell%2FARMACHEK%20SILVER.pdf?alt=media&token=4f4325ad-6821-4eb7-b323-60df50478a5c",
        },
        {
          title: "ArmaComfort AB AL PUR",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FArmacell%2FArmaComfort%20AB%20AL%20PUR.pdf?alt=media&token=647fe3a1-87b4-463d-84d3-e808391db649",
        },
        {
          title: "Armaflex Adhesive 520 with Acc",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FArmacell%2FArmaflex%20adhesive%20520%20with%20Acc.pdf?alt=media&token=503291d3-2d59-4bbe-a6b0-f47d12b98208",
        },
        {
          title: "Armalight Alu",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FArmacell%2FArmalight%20Alu.pdf?alt=media&token=2f83155c-d60a-45ac-9f47-be77562d448f",
        },
      ],
      logo: armacell,
    },
    {
      company: "AEROZEL AIROUTLETS",
      products: [
        {
          title: "Square Ceiling Diffuser",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FAerozeal%2Fceiling%20diffuser.pdf?alt=media&token=b94710fe-1ec1-426c-baaf-bddaaaf5e9f9",
        },
        {
          title: "Round Diffuser",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FAerozeal%2Fround%20diffuser.pdf?alt=media&token=c00110fa-2368-4abb-8d2c-b55322560416",
        },
        {
          title: "Jet Nozzie",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FAerozeal%2FJet%20Nozzle.pdf?alt=media&token=58d54c89-cbc7-4f0f-ba21-83c52f0b1da5",
        },
        {
          title: "Ring Jet Diffuser",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FAerozeal%2FRing%20Jet%20diff.pdf?alt=media&token=a6ed8bd9-7697-41cd-9709-0196391fd175",
        },
        {
          title: "Double Diflection Grille",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FAerozeal%2Fdouble%20deflection%20grill.pdf?alt=media&token=32c9c958-2b72-4860-95c3-f5a79dcbafca",
        },
      ],
      logo: aerozeal,
    },

    {
      company: "KIMMCO ISOVER",
      products: [
        {
          title: "Internal Insulation For Ducts",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FKimmco%2FDuct%20Insulation.pdf?alt=media&token=b81533c1-ed42-4dfe-a933-ea1c4a69208b",
        },
        {
          title: "External Insulation For Ducts",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FKimmco%2FDuct%20Insulation.pdf?alt=media&token=b81533c1-ed42-4dfe-a933-ea1c4a69208b",
        },
        {
          title: "Pipe Sections For HVAC Applications",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FKimmco%2FPipe.pdf?alt=media&token=c16bf466-aa9f-4764-a74d-7041e9bd4fd2",
        },
        {
          title: "Acoustic Insulation",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FKimmco%2FAcoustic.pdf?alt=media&token=314fe86c-7b38-4787-bf75-95f0c31797f9",
        },
      ],
      logo: kimmco,
    },
    {
      company: "FOCUS AIRCONDITIONS",
      products: [
        { title: "Flexible Duct (Insulated/Un-Insualted)", link: "" },
        {
          title: "Flexible Duct Connector",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FFocus%2FConnector.pdf?alt=media&token=4c792178-3316-4e46-a925-5f1db780d152",
        },
        {
          title: "Duct Flange System",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FFocus%2Fcrayflange.pdf?alt=media&token=bed49e9f-a5c8-436a-9c0c-bababa4f8857",
        },
        { title: "Duct Pin", link: "" },
      ],
      logo: focus,
    },

    {
      company: "PRIME AIRCONDITION LLC",
      products: [
        {
          title: "Filter Hepa",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FPrime%2FFilter%20Hepa.pdf?alt=media&token=82631cc0-c950-457c-9608-1a6370eddb25",
        },
        {
          title: "Fire Dumper Smoke Damper",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FPrime%2FFire%20Dumper%20Somke%20Damer.pdf?alt=media&token=286e1050-fe85-481d-b7d0-7fd35d35222c",
        },
        {
          title: "Non Return Damper-Pressure Relief Damper",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FPrime%2FNon%20Return%20Damper-Pressure%20Relief%20Damper.pdf?alt=media&token=3f3a3dac-f99a-4343-9771-483a00847ed2",
        },
        {
          title: "Sound attenuator",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FPrime%2Fsound%20Attenuator.pdf?alt=media&token=a81c89da-bc17-464a-b3cc-c2bd1393dc97",
        },
        {
          title: "Variable air volume",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FPrime%2FVariable%20Air%20Volume-VAV.pdf?alt=media&token=27ee88d3-7dfb-4d63-b9c4-2f3fd2ab4906",
        },
        {
          title: "Volume control damper",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FPrime%2FVolume%20Control%20Damper.pdf?alt=media&token=b3ce9a6c-6135-4874-8766-d6b3e57695f3",
        },
      ],
      logo: prime,
    },
    {
      company: "TEMBO STAR PIPE SUPPORT SYSTEM",
      products: [
        {
          title: "Pipe Hanger With EPDM Lining",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FTembo%2FPIPE%20HANGER%20WITH%20EPDM%20LINING.pdf?alt=media&token=56964eb8-d6f9-4587-a409-ac51362809a4",
        },
        {
          title: "Pipe Hanger Without Lining",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FTembo%2FPIPE%20HANGER%20WITHOUT%20LINING.pdf?alt=media&token=a936ceda-4adf-4cc8-ac13-5ebd2d66654c",
        },
        {
          title: "Sprinkler Hanger",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FTembo%2FSprinkler%20hanger.pdf?alt=media&token=432474ca-7cc2-4114-87c5-e757e70ebb94",
        },
        {
          title: "Clevis hanger",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FTembo%2FClevis%20hanger.pdf?alt=media&token=0bbc1720-cf43-4efa-85d1-3e7dad69873b",
        },
        {
          title: "Rubber Support Inserts",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FTembo%2FRUBBER%20SUPPORT%20INSERTS.pdf?alt=media&token=20fa86d4-2a47-438b-84ab-3b06418015ca",
        },
        {
          title: "Vibration Ssolator",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FTembo%2FVibration%20mounting.pdf?alt=media&token=67d5eca0-07e5-4147-aaa3-629718284f52",
        },
        {
          title: "U Strap",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FTembo%2FU%20STRAP.pdf?alt=media&token=c868df5e-0405-4699-abee-a7854a035261",
        },
      ],
      logo: tembo,
    },

    {
      company: "ABRO INDUSTRIES, INC.",
      products: [
        {
          title: "UL Classified Aluminium Foil Tape",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FAbro%2F2290-AluminumFoilTape-Sellsheet-2020.pdf?alt=media&token=efda3adc-5c3a-4f45-bf44-368a28717dfb",
        },
        {
          title: "UL Classified fsk Facing Tape",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FAbro%2FVibration%20mounting.pdf?alt=media&token=54400e7e-041a-4219-b43b-0695b1ca6717",
        },
        { title: "Silver Cloth Duct Tape", link: "" },
      ],
      logo: abro,
    },

    {
      company: "FOSTER – USA",
      products: [
        {
          title: "Duct Insulation Adhesive",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FFoster%2Fduct%20insulation%20adhesive%20%20.pdf?alt=media&token=3ab92bdc-a1ce-4476-809e-ed6498edce81",
        },
        {
          title: "Duct Insulation Coating",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FFoster%2Fduct%20insulation%20coating.pdf?alt=media&token=23fbec6b-36bb-4604-be90-663f944a4d60",
        },
        {
          title: "Duct Sealant",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FFoster%2FDUCT%20Selent.pdf?alt=media&token=a3195612-8f61-4314-8caf-39667b58f032",
        },
      ],
      logo: foster,
    },
    {
      company: "STAR TECHNOLOGIES",
      products: [{ title: "HVAC Coating/Adhesives/Duct Sealant", link: "" }],
      logo: star,
    },

    {
      company: "XCHEM",
      products: [
        {
          title: "HVAC Adhesive",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FXchem%2FChemxglue%20%2081%2010-TDS.pdf?alt=media&token=67b8bd44-4ee4-4634-88fb-ae71a5693bc1",
        },
        {
          title: "Coating",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FXchem%2FEnicoat%2030-36-TDS.pdf?alt=media&token=ae1bdb8d-3fd9-476e-adcb-1a49b0894f3c",
        },
        {
          title: "Mastic & Coating",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FXchem%2FEnicoat%20AF%20-%20TDS.pdf?alt=media&token=12af248e-ffc2-41ec-9faf-fd4a0755bdf8",
        },
        {
          title: "Duct Sealant",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FXchem%2FChemxseal%2032-17-TDS.pdf?alt=media&token=a3c25557-0f1a-4e0b-b30f-f7e1d8eb372b",
        },
      ],
      logo: xchem,
    },

    {
      company: "ICOOL ACCESSORIES",
      products: [
        {
          title: "Duct Corner",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FIcool%2FDuct%20Accessories%20-%20ICOOL12.pdf?alt=media&token=4d20e27e-7d95-424d-8ceb-59805ba7192a",
        },
        {
          title: "Duct Clamp (G-CLAMP)",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FIcool%2FDuct%20Accessories%20-%20ICOOL12.pdf?alt=media&token=4d20e27e-7d95-424d-8ceb-59805ba7192a",
        },
        {
          title: "Regulator Set",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FIcool%2FDuct%20Accessories%20-%20ICOOL3.pdf?alt=media&token=4ff7ee70-2193-4156-a6cc-1510bd063547",
        },
        {
          title: "Rapit Regulator Set",
          link:
            "https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Products%2FIcool%2FDuct%20Accessories%20-%20ICOOL4.pdf?alt=media&token=f73c9ace-64a8-4d63-b282-540350c0b83d",
        },
      ],
      logo: icool,
    },
  ];

  return (
    <div className={classes.root}>
      <h1 className={classes.mainHeading}>Our Products</h1>

      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
      >
        {products.map((i, index) => {
          return (
            <Grid
              key={index}
              item
              xs={10}
              sm={5}
              md={3}
              xl={3}
              style={{ margin: 10 }}
            >
              <div className={classes.productContainer}>
                {i.logo ? (
                  <img
                    alt={i.company + " Logo"}
                    src={i.logo}
                    className={classes.img}
                  />
                ) : (
                  <div className={classes.img} />
                )}
                <h5 className={classes.heading}>{i.company}</h5>
                {i.products.map((k) => {
                  if (k.link === "") {
                    return (
                      <p
                        className={classes.subHeading}
                        style={{ textAlign: "left" }}
                      >
                        {k.title}
                      </p>
                    );
                  } else
                    return (
                      <a href={k.link} target="_blank" rel="noreferrer">
                        <p
                          className={classes.subHeading}
                          style={{ textAlign: "left" }}
                        >
                          {k.title}
                        </p>
                      </a>
                    );
                })}
              </div>
            </Grid>
          );
        })}
      </Grid>
      <div style={{ marginTop: 200 }}>
        <Footer />
      </div>
    </div>
  );
};

export default AllProducts;
