import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// Create a theme instance.
export const createTheme = (isRTL) => {
  const theme = createMuiTheme({
    direction: isRTL ? "rtl" : "ltr",
    custom: {
      maxWidth: 1440,
    },
    thrZIndex: {
      content: 1,
      backgroundDesign: 0,
      mainHeader: 5,
      secondaryHeader: 5,
    },
    palette: {
      primary: {
        light: "#e0c3ff",
        main: "#87c846",
        dark: "#5202a5",
        contrastText: "#fff",
      },
      // primary: {
      //   light: "#18FE91 ",
      //   main: "#00d9a6;",
      //   dark: "#1fc153",
      //   // darker: "#287223",
      //   contrastText: "#fff"
      // },
      secondary: {
        light: "#e2faff",
        main: "#435462",
        dark: "#0679d4",
        contrastText: "rgba(255, 255, 255, 0.90)",
      },
      // secondary: {
      //   light: "#6b7780",
      //   main: "#3a35f8",
      //   dark: "#19232b",
      //   contrastText: "rgba(255, 255, 255, 0.90)"
      // },
      blue: {
        light: "#56d1fd",
        main: "#8f86fd",
        dark: "#3b31b5",
        dark2: "#7f12ff",
        contrastText: "rgba(255, 255, 255, 0.90)",
      },
      red: "#ff0132",
      error: {
        main: red.A400,
      },
      background: {
        default: "#fff",
      },
    },
    typography: {
      fontFamily: [
        '"Montserrat"',
        '"Qwigley"',
        '"Noto Sans"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });

  return theme;
};
