import React, { useState, useEffect, useCallback } from "react";
import { PrevButton, NextButton } from "./EmblaCarouselButtons";
import { useRecursiveTimeout } from "./useRecursiveTimeout";
import { useEmblaCarousel } from "embla-carousel/react";
import { mediaByIndex } from "../media";
import "../css/embla.css";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import hero from "assets/images/hero.png";
import armacell from "../assets/images/armacell.png";
import kimmco from "../assets/images/kimmco.png";
import tembo from "../assets/images/tembo.png";
import prime from "../assets/images/prime.png";

const useStyles = makeStyles((theme) => ({
  item1: {
    order: 1,
    paddingTop: 300,
    [theme.breakpoints.down("sm")]: {
      order: 2,
      paddingTop: 0,
    },
  },
  item2: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  mainHeading: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 50,
      fontSize: 48,
      textAlign: "center",
    },
  },
  subHeading: {
    [theme.breakpoints.down("sm")]: {},
    fontSize: 24,
    textAlign: "center",
  },
  btn: {
    background: "linear-gradient(25deg, #009B7F 5%, #87c846 90%)",
    border: 0,
    borderRadius: 27,
    boxShadow: "0 3px 5px 2px rgba(135, 200, 70, .3)",
    color: "white",
    height: 54,
    width: 250,
    // margin: 40,
    // marginBottom: 50,
    // padding: "0 30px",
    "&:hover": {
      boxShadow: "0 5px 10px 2px rgba(135, 200, 70, .6)",
      transform: "scale(1.01)",
    },
    transition: theme.transitions.create(["all"], {
      duration: 700,
      easing: theme.transitions.easing.easeInOut,
    }),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },

  heroImage: {
    marginTop: "70vh",
    maxWidth: 250,
    minHeight: 80,
    padding: 20,
    borderRadius: 8,
    objectFit: "contain",
    backgroundColor: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      padding: 10,
      width: 200,
      marginTop: 50,
    },
  },
}));

const AUTOPLAY_INTERVAL = 100000;

const Carousel = () => {
  const classes = useStyles();

  const [viewportRef, embla] = useEmblaCarousel();
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const autoplay = useCallback(() => {
    if (!embla) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [embla]);

  const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL);

  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
    stop();
  }, [embla, stop]);

  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
    stop();
  }, [embla, stop]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
    embla.on("pointerDown", stop);
  }, [embla, onSelect, stop]);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <div className="embla" id="home">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          <div className="embla__slide" key={0}>
            <div className="embla__slide__inner">
              <div
                style={{
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    // xs={10}
                    // sm={9}
                    // md={5}
                    // xl={5}
                    className={classes.item1}
                  >
                    <Typography
                      align="left"
                      color="primary"
                      variant="h2"
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        textShadow: "black 2px 2px 5px",
                      }}
                      className={classes.mainHeading}
                    >
                      Engineered for life...
                    </Typography>

                    <Typography
                      align="left"
                      color="secondary"
                      variant="h5"
                      style={{
                        marginBottom: 20,
                        color: "white",
                        textShadow: "black 2px 2px 5px",
                      }}
                      className={classes.subHeading}
                    >
                      Simple Solutions for HVAC Technology
                    </Typography>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/asas-thrworks.appspot.com/o/Option%20001.pdf?alt=media&token=16acd151-8537-4037-bd52-d4f9314d8634"
                      target="_blank"
                      rel="noopener"
                    >
                      <Button className={classes.btn}>View Profile</Button>
                    </a>
                  </Grid>
                  {/* <Grid
                    item
                    xs={10}
                    sm={9}
                    md={5}
                    xl={5}
                    className={classes.item2}
                  >
                    <img src={hero} className={classes.heroImage} />
                  </Grid> */}
                </Grid>
              </div>
              <img
                loading="eager"
                alt="Hero Image"
                className="embla__slide__img"
                src={mediaByIndex(0)}
              />
            </div>
          </div>
          <div className="embla__slide" key={1}>
            <div className="embla__slide__inner">
              <div
                style={{
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              ></div>
              <img
                loading="lazy"
                alt="Hero Image"
                className="embla__slide__img"
                src={mediaByIndex(1)}
              />
            </div>
          </div>
          <div className="embla__slide" key={2}>
            <div className="embla__slide__inner">
              <div
                style={{
                  width: "100vw",
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1,
                }}
              >
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={10} sm={9} md={3} xl={3}>
                    <img
                      alt="Armacell Logo"
                      src={armacell}
                      className={classes.heroImage}
                    />
                  </Grid>
                  <Grid item xs={10} sm={9} md={3} xl={3}>
                    <img
                      alt="Kimmco logo"
                      src={kimmco}
                      className={classes.heroImage}
                    />
                  </Grid>
                  <Grid item xs={10} sm={9} md={3} xl={3}>
                    <img
                      alt="Prime logo"
                      src={prime}
                      className={classes.heroImage}
                    />
                  </Grid>
                </Grid>
              </div>
              <img
                loading="lazy"
                alt="Hero Image"
                className="embla__slide__img"
                src={mediaByIndex(2)}
              />
            </div>
          </div>
        </div>
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </div>
  );
};

export default Carousel;
