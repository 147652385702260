import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { createTheme } from "./config/Theme";
import Home from "./Screens/Home";
import AllProducts from "./Screens/AllProducts";
import { Header } from "./Components/Header";
import Projects from "Screens/Projects";
import Message from "Screens/Message";

function App() {
  let lang;
  const isRTL = lang === "en";
  const theme = createTheme(isRTL);
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          {/* <Route path="/about">
           <About />
         </Route>
         <Route path="/users">
           <Users />
         </Route> */}
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/Products">
            <Header dark={true} />
            <AllProducts />
          </Route>
          <Route exact path="/Projects">
            <Header dark={true} />
            <Projects />
          </Route>
          <Route exact path="/Message">
            <Header dark={true} />
            <Message />
          </Route>
          <Route component={Home} />
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
