import Carousel from "../Components/Carousel";
import { Header } from "../Components/Header";
import { AboutUs } from "../Components/AboutUs";
import { OurMission } from "../Components/OurMission";
import { OurProducts } from "../Components/OurProducts";
import { Contact } from "Components/Contact";
import { OurClients } from "Components/OurClients";
import { Footer } from "Components/Footer";
import "App.css";

const Home = () => {
  return (
    <div className="App">
      <Header dark={false} />
      <Carousel />
      <AboutUs />
      <OurProducts />
      <OurMission />
      <OurClients />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
