import React from "react";
import logo from "../assets/images/logo.png";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "90vh",
    width: "100vw",
  },
  item1: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
  item2: {
    order: 2,
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
  item3: {
    order: 3,
  },
  mainHeading: {
    color: theme.palette.secondary.main,
    fontSize: 50,
    // margin: 50,
    textAlign: "center",
    fontWeight: "bold",
  },
  logo: {
    width: 250,
    [theme.breakpoints.down("sm")]: {
      width: 150,
      marginTop: 50,
    },
  },
}));

export const AboutUs = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
      id="aboutus"
    >
      <Grid item xs={10} sm={6} md={3} xl={3} className={classes.item1}>
        <h1 className={classes.mainHeading}>About Us</h1>
        <Typography align="left" color="secondary" variant="body1" style={{}}>
          ASAS System Est. (ASAS) is one of the KSA’s leading
          stockist/distributor of HVAC Industry products. We aim to meet the
          major requirements for HVAC industry by providing the most
          comprehensive range of innovative and reliable products to meet our
          customer requirements.
        </Typography>
      </Grid>
      <Grid
        item
        xs={10}
        sm={6}
        md={3}
        xl={3}
        style={{ margin: 10 }}
        className={classes.item2}
      >
        <img src={logo} className={classes.logo} alt="Asas Logo" />
      </Grid>
      <Grid
        item
        xs={10}
        sm={6}
        md={3}
        xl={3}
        style={{ margin: 10 }}
        className={classes.item3}
      >
        <h1 className={classes.mainHeading}>Our Vision</h1>
        <Typography align="left" color="secondary" variant="body1" style={{}}>
          To be a complete solutions provider in the HVAC industry, by deploying
          a process of transformation, aided by the strengthening of our
          intellectual capital which, in turn, will enable us to move up the
          value chain and provide a comprehensive offering, from engineering
          design to delivery of products, services etc.
        </Typography>
      </Grid>
    </Grid>
  );
};
